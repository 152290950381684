import { Pipe, PipeTransform } from '@angular/core';
import { Standing } from '../standing';
import { StandingsConfig } from './standings-config';

@Pipe({
  name: 'standingsFilter'
})
export class StandingsFilterPipe implements PipeTransform {
  transform(standings: Standing[], standingsConfig: StandingsConfig): Standing[] {
    return standings.filter(standing =>
        (
          standingsConfig.category === undefined
          || (
            Array.isArray(standingsConfig.category)
              ? standingsConfig.category.find(category => category == standing.driver.category)
              : standing.driver.category === standingsConfig.category
          )
        )
        && (
          standingsConfig.oemCategory === undefined
          || (
            Array.isArray(standingsConfig.oemCategory)
              ? standingsConfig.oemCategory.find(oemCategory => oemCategory == standing.driver.oemCategory)
              : standing.driver.oemCategory === standingsConfig.oemCategory
          )
        )
        && (
          standingsConfig.masterCategory === undefined
          || (
            Array.isArray(standingsConfig.masterCategory)
              ? standingsConfig.masterCategory.find(masterCategory => masterCategory == standing.driver.masterCategory)
              : standing.driver.masterCategory === standingsConfig.masterCategory
          )
        )
        && (standingsConfig.group === undefined || standing.driver.group === standingsConfig.group)
        && (standingsConfig.women === undefined || standing.driver.women === standingsConfig.women)
        && (standingsConfig.yt === undefined || standing.driver.yt === standingsConfig.yt)
    );
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StandingsConfig } from '../standings-config';
import { ResultsConfig } from '../../results/results-config/results-config';

type TCategoryPreset = {
  label: string,
  category?: number[]
};

@Component({
  selector: 'mc-standings-moderation',
  templateUrl: './standings-moderation.component.html',
  styleUrls: ['./standings-moderation.component.scss']
})
export class StandingsModerationComponent implements OnInit {

  categories: TCategoryPreset[] = [
    { label: 'All categories' },
    { label: 'Pro 1', category: [11] },
    { label: 'Pro 2', category: [12] },
    { label: 'Pro 3', category: [13] },
    { label: 'Pro 4', category: [14] },
    { label: 'Pro 5', category: [15] },
    { label: 'Pro 6', category: [16] },
    { label: 'Pro 7', category: [17] },
    { label: 'All Pro cars', category: [11, 12, 13, 14, 15, 16, 17] },
    { label: 'Street 1', category: [21] },
    { label: 'Street 2', category: [22] },
    { label: 'Street 3', category: [23] },
    { label: 'Street 4', category: [24] },
    { label: 'Street 5', category: [25] },
    { label: 'Street 6', category: [26] },
    { label: 'Street 7', category: [27] },
    { label: 'All Street cars', category: [21, 22, 23, 24, 25, 26, 27] },
    { label: 'OPEN class', category: [40] }
  ];

  oemCategories: TCategoryPreset[] = [
    { label: 'No OEM categories filter' },
    { label: 'OEM 1', category: [1] },
    { label: 'OEM 2', category: [2] },
    { label: 'OEM 3', category: [3] },
    { label: 'All OEM', category: [1, 2, 3] }
  ];

  masterCategories: TCategoryPreset[] = [
    { label: 'No Master categories filter' },
    { label: 'Porsche', category: [1] },
    { label: 'BMW', category: [2] },
    { label: 'AWD', category: [3] },
    { label: 'FWD', category: [4] },
    { label: 'All Master categories', category: [1, 2, 3, 4] }
  ];

  categoryIndex: number;
  oemCategoryIndex: number;
  masterCategoryIndex: number;

  @Input() standingsConfig: StandingsConfig;
  @Input() resultsConfig: ResultsConfig;

  @Output() standingsConfigChanged = new EventEmitter<StandingsConfig>();
  @Output() resultsConfigChanged = new EventEmitter<ResultsConfig>();


  set group(group: string|'') {
    this.standingsConfig.group = group === '' ? undefined : +group;
    this.emitStandingsConfig();
  }

  get group(): string {
    return this.standingsConfig.group === undefined ? '' : '' + this.standingsConfig.group;
  }

  set category(spec: TCategoryPreset) {
    this.categoryIndex = this.categories.indexOf(spec);
    this.standingsConfig.category = this.categories[this.categoryIndex].category;
    this.emitStandingsConfig();
  }

  get category(): TCategoryPreset {
    return this.categories[this.categoryIndex];
  }

  set oemCategory(oemCategory: TCategoryPreset) {
    this.oemCategoryIndex = this.oemCategories.indexOf(oemCategory);
    this.standingsConfig.oemCategory = this.oemCategories[this.oemCategoryIndex].category;
    this.emitStandingsConfig();
  }

  get oemCategory(): TCategoryPreset {
    return this.oemCategories[this.oemCategoryIndex];
  }

  set masterCategory(masterCategory: TCategoryPreset) {
    this.masterCategoryIndex = this.masterCategories.indexOf(masterCategory);
    this.standingsConfig.masterCategory = this.masterCategories[this.masterCategoryIndex].category;
    this.emitStandingsConfig();
  }

  get masterCategory(): TCategoryPreset {
    return this.masterCategories[this.masterCategoryIndex];
  }

  set women(women: string|'') {
    this.standingsConfig.women = women === '' ? undefined : !!+women;
    this.emitStandingsConfig();
  }

  get women(): string {
    return this.standingsConfig.women === undefined ? '' : +this.standingsConfig.women + '';
  }

  set yt(yt: string|'') {
    this.standingsConfig.yt = yt === '' ? undefined : !!+yt;
    this.emitStandingsConfig();
  }

  get yt(): string {
    return this.standingsConfig.yt === undefined ? '' : +this.standingsConfig.yt + '';
  }

  set minimumLapSeconds(n: number) {
    this.resultsConfig.minimumLapSeconds = +n;
    this.emitResultsConfig();
  }

  get minimumLapSeconds(): number {
    return this.resultsConfig.minimumLapSeconds;
  }

  set maximumLapSeconds(n: number) {
    this.resultsConfig.maximumLapSeconds = +n;
    this.emitResultsConfig();
  }

  get maximumLapSeconds(): number {
    return this.resultsConfig.maximumLapSeconds;
  }

  set rankingLapsNumber(n: number) {
    this.resultsConfig.rankingLapsNumber = +n;
    this.emitResultsConfig();
  }

  get rankingLapsNumber(): number {
    return this.resultsConfig.rankingLapsNumber;
  }

  constructor() { }

  ngOnInit() {
    this.emitStandingsConfig();
    this.emitResultsConfig();
  }

  emitStandingsConfig() {
    this.standingsConfigChanged.emit({... this.standingsConfig});
  }

  emitResultsConfig() {
    this.resultsConfigChanged.emit({... this.resultsConfig});
  }

}
